.preview {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 16px;
  line-height: 1.3;

  p {
    font-family: Helvetica, sans-serif;
    margin: 0;
    margin-bottom: 16px;
  }
}
