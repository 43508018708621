@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --amplify-font-family: 'Inter var';
}

amplify-authenticator {
  --container-height: 100%;
}

/* fonts */
@layer base {
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 100;
    src: url(./assets/fonts/FiraSans-100.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 100;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-100-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 200;
    src: url(./assets/fonts/FiraSans-200.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 200;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-200-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 300;
    src: url(./assets/fonts/FiraSans-300.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 300;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-300-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 400;
    src: url(./assets/fonts/FiraSans-400.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 400;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-400-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 500;
    src: url(./assets/fonts/FiraSans-500.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 500;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-500-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 600;
    src: url(./assets/fonts/FiraSans-600.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 600;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-600-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 700;
    src: url(./assets/fonts/FiraSans-700.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 700;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-700-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 800;
    src: url(./assets/fonts/FiraSans-800.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 800;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-800-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 900;
    src: url(./assets/fonts/FiraSans-900.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 900;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-900-italic.ttf) format('truetype');
  }
}

body {
  margin: 0;
  font-family:
    'Inter var',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* mega menu css */

.mega-menu {
  display: none;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  z-index: 0;
}

.hoverable {
  position: static;
}

.hoverable > a:after {
  content: '\25BC';
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.hoverable:hover .mega-menu {
  display: block;
}

.toggleable > label:after {
  content: '\25BC';
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.toggle-input {
  display: none;
}
.toggle-input:not(checked) ~ .mega-menu {
  display: none;
}

.toggle-input:checked ~ .mega-menu {
  display: block;
}

.toggle-input:checked + label {
  color: white;
  background: #2c5282; /*@apply bg-blue-800 */
}

.toggle-input:checked ~ label:after {
  content: '\25B2';
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

:root {
  --amplify-primary-color: #4461d4;
  --amplify-primary-tint: #4d7bf3;
  --amplify-primary-shade: #4461d4;
}
